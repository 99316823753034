.markets-page {
    padding: 3rem 14px;

    .real-time-chart {
        width: 100%;
        height: 80vh;
        margin-bottom: 3rem;
    }
}

@include for-phone-only {}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {}

@include for-big-desktop-up {}