.about {
    h1 {
        font-weight: 700;
        font-size: 30px;
        margin: 10px 0;
    }

    .header {
        width: 100%;
        background: url(../../assets/about-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: $mobileBodyPadding;

        .top {
            @include flexCB;
            flex-direction: column-reverse;

            p {
                font-weight: 200;
                font-size: 16px;
                line-height: 25px;
            }
        }

        .bottom {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            margin-top: 30px;

            .info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                .img {
                    width: 40px;
                    height: 40px;
                }

                .stat {
                    height: 40%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 10px;

                    p {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .mission,
    .who-are-we,
    .what-we-do {
        padding: $mobileBodyPadding;
        background: #181A20;


        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
        }
    }

    .mission {
        border-radius: 50px 50px 0px 0px;
    }
}

@include for-phone-only {
    .about {

        h1 {
            font-size: 25px;
        }

        .header {
            padding: $smallestBodyPadding;

            .top {
                p {
                    font-size: 14px;
                }
            }

            .bottom {
                .info {

                    .stat {
                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .mission,
        .who-are-we,
        .what-we-do {
            padding: $smallestBodyPadding;

            h2 {
                font-size: 22px;
                line-height: 25px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .about {
        h1 {
            font-size: 55px;
        }

        .header {
            padding: 40px 75px;

            .top {
                flex-direction: row;

                p {
                    font-size: 25px;
                    line-height: 30px;
                }
            }

            .bottom {
                grid-gap: 20px;

                .info {
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;

                    .img {
                        margin-right: 20px;
                        width: 80px;
                        height: 80px;
                    }

                    .stat {
                        height: 70%;
                        margin-top: 0;

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .mission,
        .who-are-we,
        .what-we-do {
            padding: 60px 75px;

            h2 {
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}

@include for-big-desktop-up {}