    .sign-in,
    .forgot-password {
        display: flex;
        padding: 30px 0;

        .form {
            width: 95%;
            margin: auto;
            padding: $mobileBodyPadding;



            h1 {
                font-size: 2.225rem;
                line-height: 1.27;
                font-weight: 300;
            }

            form {
                .form-control {
                    width: 100%;
                    margin-top: 40px;

                    label {
                        font-weight: 600;
                    }

                    .password-container {
                        display: flex;
                        align-items: center;
                        background: #fff;
                        margin-top: 10px;
                        padding-right: 10px;

                        input {
                            margin-top: 0;
                            border: none;
                        }

                        p {
                            font-size: 1.5rem;
                            color: black;
                        }
                    }
                }

                .error {
                    margin: 20px 0;
                    color: red;
                    display: none;

                    &.show-error {
                        display: block;
                    }

                    &.show-error .password-submitBtn {
                        margin-top: 0;
                    }
                }

                .password-submitBtn {
                    margin-top: 20px;
                    @include flexCB;

                    p {
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        button {
                            width: auto;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }


    @include for-phone-only {

        .sign-in,
        .forgot-password {

            .form {

                h1 {
                    font-size: 1.9rem;
                }
            }
        }
    }

    @include for-tablet-potrait-up {

        .sign-in,
        .forgot-password {

            .form {
                width: 60%;
                margin: 200px auto;

                form {
                    .password-submitBtn {
                        button {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {

        .sign-in,
        .forgot-password {
            .form {
                width: 40%;
                margin: 50px auto 180px auto;

                h1 {
                    font-size: 2.625rem;
                }

                form {


                    .password-submitBtn {
                        button {
                            width: 160px;
                        }
                    }

                }

            }

        }

        .forgot-password {
            .form {
                margin: 50px auto 100px auto;
            }
        }
    }

    @include for-big-desktop-up {}