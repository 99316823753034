.services {
    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B7BDC6;
    }

    .header {
        padding: 40px 16px;
    }

    .investments {
        padding: 40px 16px;

        .item {
            margin-bottom: 30px;

            img {
                width: 48px;
                height: 48px;
                margin-bottom: 6px
            }
        }
    }

    .client-support,
    .security {
        padding: 40px 20px;
    }


    .contact-box {
        padding: 40px 16px;

        p {
            span {
                color: $primaryColor;
            }
        }

        .btn {
            @include flexCC;

            button {
                margin-top: 30px;
                padding: 1rem 2rem;
            }

        }

    }

}


@include for-phone-only {}

@include for-tablet-potrait-up {
    .services {
        padding: 40px 24px;

        h2 {
            font-size: 32px;
            line-height: 40px;
        }

        h3 {
            font-size: 24px;
            line-height: 32px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        .investments {
            padding: 40px 24px;

            .item {
                @include flexC;


                img {
                    width: 80px;
                    height: 80px;
                }

                .info {
                    margin-left: 24px;
                }
            }
        }

        .client-support,
        .security {
            padding: 40px 24px;
        }

        .contact-box {
            padding: 40px 24px;
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .services {
        .header {
            padding: 40px 75px;

            h2 {
                font-size: 40px;
            }

        }

        .investments {
            padding: 40px 75px;

            .item {
                .logo {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        .client-support,
        .security {
            padding: 40px 75px;
        }


        .contact-box {
            padding: 40px 75px;
        }
    }
}

@include for-big-desktop-up {}