.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    background-color: $darkBg;
    padding: 0.5rem 0;

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        svg {
            color: $primaryColor;
            font-size: 1.1rem;
            margin-bottom: 0.2rem;
        }

        p {
            font-size: 0.9rem;
        }
    }
}

@include for-phone-only {}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .bottom-nav {
        display: none;
    }
}

@include for-big-desktop-up {}