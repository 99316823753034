.prices {

    h1 {
        text-align: center;
        font-size: 40px;
        color: $primaryColor;
        padding-top: 50px;
    }

    .prices-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        padding: $mobileBodyPadding;

        .card {
            height: auto;
            padding: 30px 10px;
            display: flex;
            text-align: center;
            flex-direction: column;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 5%), 0px 4px 32px rgb(58 53 47 / 5%);
            position: relative;
            border: 1px solid $primaryColor;

            .price {
                margin: 30px 0;
                font-weight: 400;

            }

            .feature-comp {
                font-size: 1.3rem;
                color: $primaryColor;
            }

            .features-list {
                width: 70%;
                margin: 30px auto 50px auto;

                li {
                    margin: 10px 0;
                    font-size: 0.9rem;
                    text-align: start;
                }
            }

            button {
                position: absolute;
                bottom: 10px;
                right: 0;
                left: 0;
                margin: 10px 50px;

            }
        }
    }

    .block {
        @include flexCB;
        flex-direction: column;
        padding: $mobileBodyPadding;

        .text {
            width: 100%;

            h1 {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 15px;
                text-align: center;
            }

            h2 {
                font-size: 25px;
                margin-bottom: 15px;
                color: $primaryColor;

                span {
                    font-weight: 300;
                    color: $darkText;
                }
            }

            h5 {
                font-size: 17px;
                font-weight: 500;
                margin: 5px 0;
            }


            .header-p {
                text-align: center
            }

            .btn {
                @include flexCC;

                button {
                    min-width: 200px;
                    margin: 20px 0;
                    padding: 15px;
                }

            }


        }

        .image {
            position: relative;
            width: 100%;
            height: 40vh;
            margin-bottom: 25px;
        }
    }
}


@include for-phone-only {
    .prices {
        h1 {
            font-size: 30px;
        }

        .prices-cards {
            padding: $smallestBodyPadding;
        }

        .block {
            .text {
                h2 {
                    font-size: 22px;
                }

                h5 {
                    font-size: 1rem;
                }
            }
        }
    }
}

@include for-tablet-potrait-up {

    .prices {
        .prices-cards {
            grid-template-columns: repeat(2, 1fr);
        }

        .block {
            flex-direction: row;

            .text {
                h1 {
                    font-size: 40px;
                    text-align: start;
                }

                h5 {
                    font-size: 20px;
                    text-align: start;
                }

                .header-p {
                    text-align: start;
                }

                .btn {
                    justify-content: flex-start;
                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .prices {


        .prices-cards {
            grid-template-columns: repeat(4, 1fr);
            padding: 80px 100px;
        }

        .block {
            padding: 50px 100px;

            .text {
                h2 {
                    font-size: 40px;
                    margin-bottom: 15px;
                }

                h5 {
                    font-size: 26px;
                    text-align: start;
                    margin: 5px 0;
                }
            }

            .image {
                height: 70vh;
            }
        }


    }
}

@include for-big-desktop-up {}